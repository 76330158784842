import { graphql } from "gatsby";
import Img from "gatsby-image";

import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const ThisPage = ({ data, location }) => {
  const imgUrl = "/images/people/dieter-j-kalkowski.jpg";
  const fullName = "Dieter J. Kalkowski";
  const zoomUrl =
    "https://us02web.zoom.us/j/82565447314?pwd=UWpENnNCVjJLdzBRZ0xpa2ZDL1M1Zz09";
  const zoomPhoneNumber = "(669) 900-9128";
  const zoomMeetingId = "825 6544 7314";
  const zoomMeetingPasscode = "636559";
  const birthDate = "April 18, 1934";
  const deathDate = "November 24, 2020";
  const memorialDate = "Saturday December 12th, 2020";
  const memorialTime = "10am PST / 1pm EST / 7pm CET";
  const inviteText = `Due to the current challenges we all face and global travel restrictions, in lieu of a physical ceremony, the family and friends of Dieter (aka Dean) J. Kalkowski invite you to a virtual service honoring his life.`;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fvk2eemjc";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rv1l2mlwd4cl2hamrc4kqwpl4654k9k2q285kmdh";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    >
      <b>Zoom Funeral Dress Code</b>
      <p>
        Similar to an in-person funeral, you’ll want to consider your dress code
        even when you’re connecting digitally. Please dress as you would for a
        traditional funeral.
      </p>
      <p>
        Your attire won’t be as strict as if you were attending in-person, but
        it’s a sign of respect to wear neutral colors that aren’t a
        distraction. 
      </p>
      <b>Sympathy and Sharing Stories Online</b>
      <p>
        After the planned program everyone will have the opportunity to unmute
        and share a story or memory with the other guests. Feel free to record
        your message earlier and email it to be shared at the service. Please
        choose a bright quiet spot with a subtle background
      </p>
      <p>
        <b>1970s Paintings by Dean</b>
      </p>
      <Grid columns={["none", "1fr 1fr"]}>
        <a href="/images/people/dieter-j-kalkowski-1.jpeg">
          <Img
            title="1970s painting by Dean"
            alt="1970s painting by Dean"
            fluid={data.imageOne.childImageSharp.fluid}
            sx={{ maxWidth: "400px" }}
          />
        </a>
        <a href="/images/people/dieter-j-kalkowski-2.jpeg">
          <Img
            title="1970s painting by Dean"
            alt="1970s painting by Dean"
            fluid={data.imageTwo.childImageSharp.fluid}
            sx={{ maxWidth: "400px" }}
          />
        </a>
      </Grid>
    </StoryPage>
  );
};

export default ThisPage;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { regex: "/dieter-j-kalkowski-1.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageTwo: file(relativePath: { regex: "/dieter-j-kalkowski-2.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
